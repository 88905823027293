<template>
  <div id="mian">
    <!-- 批量订单查询 -->
    <div>
      <!-- 上面表单框 -->
      <div class="con_from">
        <div class="lineP">
          <div class="item">
            <i>商户名称</i>
            <el-input
              v-model="query.keyword"
              maxlength="128"
              suffix-icon="el-icon-search"
              placeholder="商户名称/编号"
            ></el-input>
          </div>
          <!-- <el-select v-model="query.mchId" placeholder="请选择商户名称">
            <el-option
              v-for="item in MerchantsDropList"
              :key="item.mchId"
              :label="item.mchName"
              :value="item.mchId">
            </el-option>
          </el-select> -->
          <div class="item itemTime">
            <i>统计日期</i>
            <el-date-picker
              v-model="query.settleStartDate"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
            <span style="padding-left: 10px; padding-right: 10px">-</span>
            <el-date-picker
              v-model="query.settleEndDate"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </div>
          <div class="itembtn" style="width:12%;">
            <el-button class="searchBt" @click="onSearch">查询</el-button>
          </div>
        </div>
      </div>
      <div class="tab1">
        <table class="tab">
          <tr class="tab_title">
            <td style="width: 180px">
              <p>统计日期</p>
            </td>
            <td style="width: 200px">
              <p>商户名称</p>
            </td>
            <td>
              <p>商户编号</p>
            </td>
            <td>
              <p>交易净额(元)</p>
            </td>
            <td>
              <p>订单总数(笔)</p>
            </td>
            <td>
              <p>成功订单(笔)</p>
            </td>
            <td>
              <p>
                <span>成功率(%)</span>
                <i
                  @click="setOrder"
                  class="el-icon-d-caret"
                  style="margin-left: 10px"
                ></i>
              </p>
            </td>
          </tr>
          <tr v-for="(item, index) in tableData" :key="index">
            <td>
              <p>{{ item.settleDate }}</p>
            </td>
            <td>
              <p>{{ item.mchName }}</p>
            </td>
            <td>
              <p>{{ item.mchId }}</p>
            </td>
            <td>
              <p>{{ (item.tradeAmount / 100) | formatMoney }}</p>
            </td>
            <td>
              <p>{{ item.orderNum }}</p>
            </td>
            <td>
              <p>{{ item.tradeNum }}</p>
            </td>
            <td>
              <p>{{ item.successRate }}</p>
            </td>
          </tr>
        </table>
      </div>
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="query.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { shopStatistics } from "@/api/data/data.js";
import { merchantsDrop } from "@/api/common.js";
import { formatDate } from "@/utils/common.js";
import { mapState, mapMutations } from "vuex";

export default {
  data() {
    return {
      value1: "",
      value2: "",
      crumbs: "批量查询",
      query: {
        pageNo: 1,
        pageSize: 10,
        mchId: "",
        settleStartDate: formatDate(
          new Date() - 24 * 60 * 60 * 1000,
          "yyyy-MM-dd"
        ),
        settleEndDate: formatDate(
          new Date() - 24 * 60 * 60 * 1000,
          "yyyy-MM-dd"
        ),
        keyword: "",
        orderBy: "",
      },
      tableData: [],
      MerchantsDropList: [],
      total: 0,
    };
  },
  activated() {
    this.getLists();
    this.getMerchantsDrop();
  },
  computed: {
    ...mapState({
      shtjDetail: "data_shtjQuery",
    }),
  },
  created() {
    if (this.shtjDetail) {
      this.query = this.shtjDetail.query;
    }
    this.getLists(false);
    this.getMerchantsDrop();
    this.$enter(this.$route.path, this.onSearch);
  },

  methods: {
    ...mapMutations({
      setShtjDeal: "data_setShtj",
    }),
    //菜单切换
    navClick(data) {
      this.crumbs = data;
    },
    // 排序
    setOrder() {
      this.query.orderBy =
        this.query.orderBy == ""
          ? "ASC"
          : this.query.orderBy == "ASC"
          ? "DESC"
          : "ASC";
      this.getLists();
    },
    // 获取列表数据
    getLists(reset = true) {
      if (reset) {
        (this.query.pageNo = 1),
          (this.query.keyword = this.query.keyword.trim());
      }
      if (!this.query.settleStartDate || !this.query.settleEndDate) {
        this.$message.error("统计日期时间段必选");
        return;
      }
      shopStatistics(this.query)
        .then((res) => {
          this.tableData = res.resultData.settles;
          if (this.tableData.length == 0) {
            this.$message({
              message: "暂无数据",
              duration: 1500,
            });
          }
          this.total = res.resultData.totalNum;
        })
        .catch((res) => {});
    },
    // 获取商户下拉
    getMerchantsDrop() {
      merchantsDrop()
        .then((res) => {
          this.MerchantsDropList = res.resultData;
        })
        .catch();
    },
    // 查询
    onSearch() {
      this.getLists();
    },
    // 页面大小改变
    handleSizeChange(size) {
      this.query.pageSize = size;
      this.getLists();
    },
    handleCurrentChange(page) {
      this.query.pageNo = page;
      this.getLists(false);
    },
  },
  beforeDestroy() {
    let data = {
      query: this.query,
    };
    this.setShtjDeal(data);
  },
};
</script>
<style scoped>
.con_from .lineP .item{
  margin-left: 6%;
}
.con_from .lineP .item:first-child {
    margin-left: 0%;
}
</style>
